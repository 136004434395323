export enum FeatureFlag {
    SupportsApl = 'approvedProductList',
    SupportDirectToVendor = 'directOrderSubmit',
    SupportsUpcCorrection = 'upcCorrection',
    SupportsIpcBrowsers = 'webhub',
    NewVendors = 'newVendorPromotions',
    NewProducts = 'newProductPromotions',
    StockedNotScanned = 'stockedNotScanned',
    MissedDeals = 'allMissedDealsPage',
    SupportsReceiving = 'receiving',
    DepartmentBuyer = 'departmentBuyer',
    RetailerSkuLookup = 'retailerSKULookup',
    CameraScanning = 'scandit',
    Samples = 'samples'
}

export class FeatureFlagParameter {
    prevent: FeatureFlag;
    allow: FeatureFlag;

    constructor(options?: Partial<FeatureFlagParameter>) {
        Object.assign(this, options);
    }
}

export enum LaunchDarklyFeatureFlag {
    ReleaseSupplierManagementBeta = 'release-supplier-management-beta',
    ReleaseSavedItems = 'release-saved-items',
    ReleaseIntelligentOrderingSuggestion = 'enable-intelligent-ordering-suggestion',
    EnableOrderPlusCustomerShortNameFiltering = 'enable-order-plus-customer-short-name-filtering',
    EnableSuggestedOrdering = 'enable-suggested-ordering'
}

